.App {
  position: relative;
  text-align: center;
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
  background:radial-gradient(circle, rgba(255,194,0,1) 38%, rgba(254,109,1,1) 100%);

}

.Logo {
  position: absolute;
  top: 43px;
  left: 50%;
  width: 260px;
  transform: translateX(-50%);
}

.Logo2 {
  position: absolute;
  bottom: 43px;
  left: 50%;
  width: 260px;
  transform: translateX(-50%);
}


